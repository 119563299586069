import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {ToastsService} from './toasts.service';
import {AngularFireFunctions} from '@angular/fire/functions';


@Injectable({
    providedIn: 'root'
})
export class ProjectsService {

    private readonly _templates = new BehaviorSubject<[]>([]);
    readonly templates$ = this._templates.asObservable();

    get templates(): [] {
        return this._templates.getValue();
    }

    set templates(val: []) {
        this._templates.next(val);
    }

    private readonly _newTemplates = new BehaviorSubject<[]>([]);
    readonly newTemplates$ = this._newTemplates.asObservable();

    get newTemplates(): [] {
        return this._newTemplates.getValue();
    }

    set newTemplates(val: []) {
        this._newTemplates.next(val);
    }

    departmentEmailsPerLocation = {
        'Arlington Heights': {
            'projectmanagementoperations@claritychi.com': 'projectmanagementoperationsah@claritychi.com',
            'projectmanagementdirectors@claritychi.com': 'projectmanagementdirectorsah@claritychi.com',
        }
    };

    locations = [
        'Loop',
        'River North',
        'Lakeview - Broadway',
        'Lakeview - Belmont',
        'Arlington Heights',
        'Evanston',
        'Mokena',
        'Remote',
    ];

    tmsLocations = [
        'Loop',
        'Arlington Heights',
    ];

    phpIopLocations = [
        'Loop',
        'Arlington Heights',
        'Evanston',
        'Mokena',
        'Virtual',
    ];

    licenses = [
        'N/A',
        'MD',
        'DO',
        'PA-C',
        'PsyD',
        'PsyD (PD)',
        'AMFT',
        'LMFT',
        'MFT',
        'LCSW',
        'LSW',
        'MSW',
        'LCPC',
        'LPC',
        'MA',
        'MS',
        'NP',
    ];

    // TODO finalize this
    department_roles = {
        'Psychological Services': [
            {'label': 'Clinical Director FT1', 'value': 'CD FT1'},
            {'label': 'Clinical Supervisor FT1', 'value': 'CS FT1'},
            {'label': 'Clinical Supervisor FT2', 'value': 'CS FT2'},
            {'label': 'Clinical Supervisor PT1', 'value': 'CS PT1'},
            {'label': 'Clinical Supervisor PT2', 'value': 'CS PT2'},
            {'label': 'Therapist I FT1', 'value': 'TH1 FT1'},
            {'label': 'Therapist I FT2', 'value': 'TH1 FT2'},
            {'label': 'Therapist I PT1', 'value': 'TH1 PT1'},
            {'label': 'Therapist I PT2', 'value': 'TH1 PT2'},
            {'label': 'Therapist II FT1', 'value': 'TH2 FT1'},
            {'label': 'Therapist II FT2', 'value': 'TH2 FT2'},
            {'label': 'Therapist II PT1', 'value': 'TH2 PT1'},
            {'label': 'Therapist II PT2', 'value': 'TH2 PT2'},
            {'label': 'Psychologist FT1 A', 'value': 'Psychologist FT1 A'},
            {'label': 'Psychologist FT1 B', 'value': 'Psychologist FT1 B'},
            {'label': 'Psychologist FT2 A', 'value': 'Psychologist FT2 A'},
            {'label': 'Psychologist FT2 B', 'value': 'Psychologist FT2 B'},
            {'label': 'Psychologist PT1 A', 'value': 'Psychologist PT1 A'},
            {'label': 'Psychologist PT1 B', 'value': 'Psychologist PT1 B'},
            {'label': 'Psychologist PT2 A', 'value': 'Psychologist PT2 A'},
            {'label': 'Psychologist PT2 B', 'value': 'Psychologist PT2 B'},
            {'label': 'DocPrac S1 C', 'value': 'DocPrac S1 C'},
            {'label': 'DocPrac S1 D', 'value': 'DocPrac S1 D'},
            {'label': 'DocIntern S2', 'value': 'DocIntern S2'},
            {'label': 'MasterIntern S3', 'value': 'MasterIntern S3'},
        ],
        'Psychiatry': [
            {'label': 'Psychiatrist', 'value': 'Psychiatrist'},
            {'label': 'Physician Assistant', 'value': 'PA'},
            {'label': 'Nurse Practitioner', 'value': 'NP'},
        ],
        'Operations': [
            {'label': 'Practice Manager', 'value': 'PM'},
            {'label': 'Patient Care Coordinator', 'value': 'PCC'},
        ],
        'Marketing': [],
        'Reimbursement': [],
        'Information Technology': [],
        'Human Resources': [],
        'Finance': [],
        'Leadership': [],
        'Other': [],
    };

    departments = Object.keys(this.department_roles);

    subDepartments = {
        'Psychological Services': ['Therapy', 'Testing', 'PHP/IOP'],
    };

    constructor(private cloudFunctions: AngularFireFunctions, private toastsService: ToastsService, private http: HttpClient) {
        this.getTemplates();
        this.getNewTemplates();
    }

    getTemplates() {
        this.templates = [];
        const url = environment.apiBaseUrl + 'project-templates';
        this.http.get(url, {observe : 'response'}).subscribe(result => {
            if (result.status === 200) {
                // @ts-ignore
                this.templates = result.body;
                console.log(this.templates);
            }
        }, error => {
            this.toastsService.showSnackBar('Error loading templates');
        });
    }

    getNewTemplates() {
        this.newTemplates = [];
        const url = environment.apiBaseUrl + 'new-project-templates';
        this.http.get(url, {observe : 'response'}).subscribe(result => {
            if (result.status === 200) {
                // @ts-ignore
                this.newTemplates = result.body;
                console.log(this.newTemplates);
            }
        }, error => {
            this.toastsService.showSnackBar('Error loading new templates');
        });
    }

    deleteAtPath(path) {
        const deleteFn = this.cloudFunctions.httpsCallable('recursiveDelete');
        deleteFn({ path: path, password: 'Clarity9404!' }).subscribe(result => {
            console.log(result);
        }, error => {
            console.log(error);
        });
    }

}
