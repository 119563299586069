import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../services/projects.service';
import {Observable, Subscription, throwError} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {GlobalService} from '../../services/global.service';
import {AuthService} from '../../auth/auth.service';
import {map, startWith} from 'rxjs/operators';
import {GroupsService} from '../../services/groups.service';

@Component({
    selector: 'app-new-project-dialog',
    templateUrl: 'new-project-dialog.html',
    styleUrls: ['new-project-dialog.scss']
})
export class NewProjectDialogComponent implements OnInit, OnDestroy {

    category;
    templates: any[];
    newTemplates: any[];
    projectFormGroup: FormGroup;
    templatesSubscription: Subscription;
    newTemplatesSubscription: Subscription;

    constructor(
        private afs: AngularFirestore,
        private toastsService: ToastsService,
        private authService: AuthService,
        public projectsService: ProjectsService,
        public globalService: GlobalService,
        private groupsService: GroupsService,
        public dialogRef: MatDialogRef<NewProjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        this.category = data.category || null;
    }

    ngOnInit() {
        this.globalService.saving = true;
        this.projectFormGroup = this._formBuilder.group({
            templateCtrl: [null, Validators.required],
            locationCtrl: [null, Validators.required],
            firstNameCtrl: [null, Validators.required],
            lastNameCtrl: [null, Validators.required],
            department: [null, Validators.required],
            subDepartment: [null],
            role: [null, Validators.required],
            licenseCtrl: ['N/A', Validators.required],
            titleCtrl: [null],
            phoneCtrl: [null],
            personalEmailCtrl: [null],
            dueDateCtrl: ['', Validators.required],
        });
        this.templatesSubscription = this.projectsService.templates$.subscribe(templates => {
            this.templates = templates.filter(template => template['category'] === this.category);
            console.log('Templates:', this.templates);
            this.checkIfTemplatesLoaded();
        });

        this.newTemplatesSubscription = this.projectsService.newTemplates$.subscribe(templates => {
            this.newTemplates = templates.filter(template => template['category'] === this.category);
            console.log('New Templates:', this.newTemplates);
            this.checkIfTemplatesLoaded();
        });
    }

    checkIfTemplatesLoaded() {
        if (this.templates && this.newTemplates) {
            this.globalService.saving = false;
        }
    }

    saveProject() {
        try {
            this.globalService.saving = true;
            const template = this.projectFormGroup.get('templateCtrl').value;
            const location = this.projectFormGroup.get('locationCtrl').value;
            const due_date = this.projectFormGroup.get('dueDateCtrl').value;
            const first_name = this.projectFormGroup.get('firstNameCtrl').value;
            const last_name = this.projectFormGroup.get('lastNameCtrl').value;
            const license = this.projectFormGroup.get('licenseCtrl').value;
            const title = this.projectFormGroup.get('titleCtrl').value;
            const cell_phone = this.projectFormGroup.get('phoneCtrl').value;
            const personal_email = this.projectFormGroup.get('personalEmailCtrl').value;
            const updated_by = this.authService.user.displayName;
            const updated = new Date();

            const newProject = {
                first_name: first_name,
                last_name: last_name,
                first_name_lowercase: first_name.toLowerCase(),
                last_name_lowercase: last_name.toLowerCase(),
                template: template.name,
                location: location,
                license: license,
                title: title,
                role: this.projectFormGroup.get('role').value,
                department: this.projectFormGroup.get('department').value,
                sub_department: this.projectFormGroup.get('subDepartment').value,
                cell_phone: cell_phone,
                personal_email: personal_email,
                created: new Date(),
                finished: false,
                archived: false,
                due_date: due_date,
                category: this.category,
                updated_by: updated_by,
                updated: updated
            };
            const tasks = this.generateTasksFromTemplate(template, due_date, first_name, last_name, location);
            this.saveProjectToFirestore(newProject, tasks);
        } catch (e) {
            console.log(e);
            this.toastsService.showSnackBar(e, 'error', 5000);
            this.globalService.saving = false;
        }
    }

    saveProjectToFirestore(project, tasks) {
        // archive test projects
        if (project.first_name.toLowerCase() === 'test' || project.first_name.toLowerCase() === 'testing') {
            project.archived = true;
        }
        const batch = this.afs.firestore.batch();
        const id = this.afs.createId();
        const projectsRef = this.afs.firestore.doc('projects/' + id);
        batch.set(projectsRef, project);

        // alert all groups to project creation (unless TEST, TEST)
        if (project.first_name.toLowerCase() !== 'test' && project.first_name.toLowerCase() !== 'testing') {
            if (this.category === 'Offboarding') {
                const newEmailRef = this.afs.firestore.collection('mail').doc();
                const groups = this.groupsService.getGroupsAssignedToProject(tasks);
                // add individual users to notification list
                // groups.push('tjavidan@claritychi.com');
                const call_to_action_url = 'https://dashboard.claritychi.com/projects/' + id;
                const project_name = project.first_name + ' ' + project.last_name;
                const email_data = {
                    to: groups,
                    from: 'Clarity Clinic <noreply@claritychi.com>',
                    replyTo: 'Clarity Clinic <noreply@claritychi.com>',
                    template: {
                        name: 'termed-date-notification',
                        data: {
                            subject: 'TERMED:' + project_name + ' has been terminated',
                            project_name: project_name,
                            termed_date: project.due_date.toDateString(),
                            call_to_action_text: project_name,
                            call_to_action_url: call_to_action_url,
                        }
                    }
                };
                batch.set(newEmailRef, email_data);
            } else if (this.category === 'Onboarding') {
                const newEmailRef = this.afs.firestore.collection('mail').doc();
                const groups = this.groupsService.getGroupsAssignedToProject(tasks);
                // add individual users to notification list
                groups.push('tjavidan@claritychi.com');
                const call_to_action_url = 'https://dashboard.claritychi.com/projects/' + id;
                const project_name = project.first_name + ' ' + project.last_name;
                const email_data = {
                    to: groups,
                    from: 'Clarity Clinic <noreply@claritychi.com>',
                    replyTo: 'Clarity Clinic <noreply@claritychi.com>',
                    template: {
                        name: 'created-date-notification',
                        data: {
                            subject: 'CREATED:' + project_name + ' has been created',
                            project_name: project_name,
                            start_date: project.due_date.toDateString(),
                            call_to_action_text: project_name,
                            call_to_action_url: call_to_action_url,
                        }
                    }
                };
                batch.set(newEmailRef, email_data);
            }
        } else {
            console.warn('Skipping email notification for TEST, TEST!');
        }

        for (const task of tasks) {
            task['project_id'] = id;
            const tasksRef = projectsRef.collection('tasks').doc();
            batch.set(tasksRef, task);
        }
        batch.commit().then(() => {
            this.globalService.saving = false;
            this.closeDialog(id);
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
            this.globalService.saving = false;
        });
    }

    generateTasksFromTemplate(template, relative_to, first_name, last_name, location) {
        let order = 0;
        for (const task of template.tasks) {
            if (task['due_relative_to_create_date']) {
                task['due_date'] = this.addDays(new Date(), task.relative_due_date);
            } else {
                task['due_date'] = this.addDays(relative_to, task.relative_due_date);
            }
            task['order'] = order;
            task['completed'] = false;
            task['first_name'] = first_name;
            task['last_name'] = last_name;
            task['first_name_lowercase'] = first_name.toLowerCase();
            task['last_name_lowercase'] = last_name.toLowerCase();

            // replace AH-specific emails
            if (this.projectsService.departmentEmailsPerLocation[location] && this.projectsService.departmentEmailsPerLocation[location][task['assigned_to']]) {
                task['assigned_to'] = this.projectsService.departmentEmailsPerLocation[location][task['assigned_to']];
            }

            // replace PHP/IOP-specific emails
            if (template.name.includes('PHP/IOP') && ['projectmanagementdirectors@claritychi.com', 'projectmanagementdirectorsah@claritychi.com'].includes(task['assigned_to'])) {
                task['assigned_to'] = 'projectmanagementphpiopdirectors@claritychi.com';
            }
            order++;
        }
        return template.tasks;
    }

    departmentChanged() {
        const department = this.projectFormGroup.get('department').value;
        if (department) {
            this.projectFormGroup.get('role').setValue(null);
        }
    }

    addDays(date, days) {
        if (!Number.isInteger(days)) {
            throw new Error('Relative due date must be an integer');
        }
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(id = null) {
        this.dialogRef.close(id);
    }

    ngOnDestroy() {
        this.templatesSubscription.unsubscribe();
        this.newTemplatesSubscription.unsubscribe();
    }

}
